import {Button} from "reactstrap";
import { useState, useContext } from "react";
import { CheckedProjectsContext } from "../../contexts/checked-projects.context.jsx";

import "./checked-projects-list-item.styles.scss";

export const CheckedProjectsListItem = ({checkedProject}) => {

    //contexts
    const {checkedProjects, setCheckedProjects, deleteFromCheckedProjects} = useContext(CheckedProjectsContext);


    return (
        <div className="checked-projects-list-item">
            <span>{checkedProject.id}</span>
            <span>{checkedProject.name}</span>
            <Button 
                className="delete-projekt-from-checked-list" 
                color="danger"
                size="sm"
                onClick={()=>deleteFromCheckedProjects(checkedProject.id)}
                title="Aus der Liste entfernen"
            >X</Button>
        </div>
    )
}


