import { useEffect, useRef } from "react";
import { NativeButton } from "../native-button/native-button.component.jsx"
import './form-input-multiple-values.styles.scss';


const FormInputMultipleValues = ({isValid,deleteFromMultiInput,clearList,values,label,isDisabled,...otherProps}) => {

    //Mit useRef werden die Felder, wenn invalide sind, fukussiert und pink gefärbt.
    const inputRef = useRef();

    useEffect(()=>{
        if(!isValid){
            inputRef.current.focus();
            inputRef.current.style.backgroundColor = "pink"
        }else{
            inputRef.current.style.backgroundColor = null
        }
    },[isValid])


    return (
        <div className="form-input-multiple-values-wrapper">

            <input 
                ref={inputRef} 
                className='form-input-multiple-values' 
                disabled={isDisabled}
                {...otherProps} 
                />

            {   values&&
                <div className={`multiple-input-values-container`}>

                    {/** wenn disabled das class disabled hinzufügen */}
                    <span className={`${isDisabled?'disabled':''}`}>
                        {
                            values.map((value,index) => {

                                return (
                                    <div key={index} className={`multiple-input-value-wrapper`}>

                                        <span id="multiple-input-value">
                                            {value}
                                        </span>

                                        <NativeButton 
                                            type="button" 
                                            id="delete-from-multiple-input-values" 
                                            buttonType={"delete"}
                                            onClick={()=>deleteFromMultiInput(index,otherProps.name)}
                                        >
                                            X
                                        </NativeButton>

                                    </div>
                                )
                            })
                        }

                        { values.length > 0 &&//nur anzeigen wenn Liste nicht leer ist.                 
                            <NativeButton 
                                type="button" 
                                id="clear-multiple-input-values" 
                                buttonType={"clear"}
                                onClick={clearList}
                            >
                                Leeren
                            </NativeButton>
                        }                
                    </span>

                </div>
            }

            {  label&& 

                <label className={
                        `${otherProps.value && otherProps.value.length?'shrink':''} form-input-multiple-values-label`
                    }   
                >
                    {label}
                </label>
            }            
        </div>
    );
}

export {FormInputMultipleValues};