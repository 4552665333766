import { useEffect } from 'react';
import { useRef } from 'react';
import './form-input.styles.scss';

const FormInput = ({isValid,label,className,...otherProps}) => {

    //Mit useRef werden die Felder, wenn invalide sind, fukussiert und pink gefärbt.
    const inputRef = useRef();

    useEffect(()=>{
        if(!isValid){
            inputRef.current.focus();
            inputRef.current.style.backgroundColor = "pink"
        }else{
            inputRef.current.style.backgroundColor = null
        }
    },[isValid])
    

    return (
        <div className={`form-input-wrapper ${className}`} >
            <input 
                className='form-input'
                ref={inputRef}
                {...otherProps} 
            />
  
            {  label&&          
                (<label 
                    className={
                        `${otherProps.value?'shrink':''} 
                        form-input-label`
                    }   
                >
                    {label}
                </label>)
            }            
        </div>
    );
}

export {FormInput};