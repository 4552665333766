import "./projects-overview.route.styles.scss";

import { useEffect, useState, useContext } from "react";
import {Button} from "reactstrap";
import axios from "axios";
import {ProjectList} from "../../components/projects-list/project-list.component.jsx";
import { CheckedProjectsContext } from "../../contexts/checked-projects.context.jsx";
import { ProjectsStatusContext } from "../../contexts/projects-status.context.jsx";
import { Confirmation } from "../../contexts/confirmation.context.jsx";
import { Information } from "../../contexts/info-msg.context.jsx";
import { SpinnerContext } from "../../contexts/spinner.context.jsx";
import { ErrorMsgContext } from "../../contexts/error-msg.context.jsx";
import { useNavigate } from "react-router-dom";
import {FormInput} from "../../components/form-input/form-input.component.jsx";
import { GlobalVariablesContext } from "../../contexts/global-variables.context.jsx";
import { AuthenticationContext } from "../../contexts/authentication.context.jsx";

const ProjectsOverview = () =>{
    //Projekte laden,Suchen und Filtern
    const [searchedText, setSearchedText] = useState("");
    const [projectsList, setProjectsList] = useState([]);
    const [filteredProjectList, setFilteredProjectList] = useState([]);
    const [loadProgress, setLoadProgress] = useState(0);


    // Suchergebnisse auf mehreren Seiten aufteilen (Effinzienter bei der Suche)
    const [chunkedProjectsArray, setChunkedProjectsArray] = useState([]);
    const [chunkToRender, setChunkToRender] = useState(0);
    const [chunksCount, setChunksCount] = useState(chunkedProjectsArray.length);
    const [startChunk, setStartChunk] = useState(0)

    //Contexts
    const { checkedProjects, setCheckedProjects, resetCheckedProjects, setOpenCheckedProjectsList,deleteFromCheckedProjects} = useContext(CheckedProjectsContext);
    const {areProjectsUpdated,setAreProjectsUpdated} = useContext(ProjectsStatusContext);
    const {setConfirmOpen,setConfirmTitle,setConfirmMsg,setOnConfirm,onConfirm} = useContext(Confirmation);
    const {setInfoOpen,setInfoMsg} = useContext(Information);
    const {errorOpen,setErrorOpen,setErrorMsg} = useContext(ErrorMsgContext);
    const {setSpinnerOpen} = useContext(SpinnerContext);
    const {apiUrl,apiKey} = useContext(GlobalVariablesContext);
    const { isAuthenticated, isLoggedIn, userData } = useContext(AuthenticationContext);


    //Navigate
    const navigate = useNavigate();



    //wann immer projectsList geändert wird. 
    useEffect(()=>{
        //wenn suchtext nicht leer ist Filterung durchführen.
        if(searchedText.length > 1){
            filterProjects();
        }
        //wenn suchtext leer ist alle Projekte dem filteredProjects zuweisen.
        else{
            setFilteredProjectList(projectsList);
        }

    },[projectsList]);


    // nur einmal beim initiieren der Homepage.
    useEffect (()=>{
        getAllProjects();

        //überprüfen ob ein suchtext im localstorage vorhanden ist.
        if(localStorage.getItem("searchedText") && localStorage.getItem("searchedText").length > 0){
            //text aus cache annehmen.
            setSearchedText(localStorage.getItem("searchedText"));
            //gecachten suchtext zurücksetzen.
            localStorage.setItem("searchedText","");
        }
    },[]);

    // jedes mal sich areProjectsUpdated ändert projekte neu laden..
    useEffect (()=>{
        getAllProjects();
    },[areProjectsUpdated]);
    

    useEffect (()=>{
        // jedes mal sich suchtext ändert filterProjects aufrufen.
        filterProjects();

        //suchtext in localstorage speichern, um beim zurückkehren zur Projekten-Übersicht den gesuchte text beizubehalten.
        localStorage.setItem("searchedText",searchedText);

    },[searchedText])


    //wann immer filteredProjectList sich ändert. 
    useEffect(()=>{

        const chunkSize = 100;//Anzahl der Ergebnisse pro seite

        if(filteredProjectList.length > 0){
            
            const chunkedProjectsList = filteredProjectList.reduce((chukedArray, project, index) => { 
                const chunkIndex = Math.floor(index/chunkSize)
              
                if(!chukedArray[chunkIndex]) {
                  chukedArray[chunkIndex] = [] // neues subArray starten.
                }
                chukedArray[chunkIndex].push(project)
              
                return chukedArray
            }, []);
    
            setChunkedProjectsArray(chunkedProjectsList);
        }
    },[filteredProjectList])      


    // wenn chunkedProjectsArray geändert wird die cunks Anzahl anpassen.
    useEffect(()=>{
        setChunksCount(chunkedProjectsArray.length);
    },[chunkedProjectsArray])


    //--------------------------------------------------------------------------
    //              Kontroll-Funktionen
    //--------------------------------------------------------------------------

    /**
     * Das Projekt kompllet löschen sowohl in DB als auch auf Awr-Cloud.
     * @returns 
     */
    const onDeleteHandler = async () => {

        //wenn keine projekte ausgewählt sind. funktion terminieren.
        if (areNoProjectsSelected()) return;

        setConfirmOpen(true);
        setConfirmTitle("Projekte löschen");
        setConfirmMsg("Sollen die ausgwählten Projekte, mit zugehörigen Berichten unwiderruflich gelöscht werden?")
        //hier 2 verschachtelete funktionen rein passen. die 2. wird später durch die 1. in
        // confirm-msg-component aufgerufen.
        setOnConfirm(()=> async()=>{

            setSpinnerOpen(true);

            try{
                const deleteProjectResponse = await axios({
                    method:"post",
                    url:`${apiUrl}/deleteProjects`,
                    headers:{
                    'content-type': 'application/json',
                    },
                    data:{ 
                        function:"deleteProjects",
                        checkedProjects,
                        apiKey:apiKey  
                    }
                });

                const isDeleted = await deleteProjectResponse.data; 
    

                setSpinnerOpen(false);

                if(isDeleted.failureProjects.length > 0){
                    let errMsg = "Projekte mit ids : "
                    for(const id of isDeleted.failureProjects){
                        errMsg += `(${id}) `;
                    }
                    errMsg += "konnten nicht gelöscht werden."

                    setErrorMsg(errMsg);
                    setErrorOpen(true);
                }
                else{

                    setInfoMsg("Projekte wurden gelöscht.");
                    setInfoOpen(true);
                }

                //checked projects leeren.
                setCheckedProjects([]);

                //projekte neu laden um aktuell zu halten
                setAreProjectsUpdated(true);
            }
            catch (error) {

                setSpinnerOpen(false);

                setErrorMsg("Verbindungsfehler! Es konnten keine Projekte entfernt werden.");
                setErrorOpen(true);

            }

        });

    }

    /**
     * Das Projekt auf ondemand umstellen. Somit werden Berichte nur noch auf Anforderung erstellt.
     * @returns 
     */
    const onDeactivateHandler = async () => {

        //wenn keine projekte ausgewählt sind. funktion terminieren.
        if (areNoProjectsSelected()) return;


        setConfirmOpen(true);
        setConfirmTitle("Projekte deaktivieren");
        setConfirmMsg("Sollen die ausgwählten Projekte deaktiviert werden?")
        //hier 2 verschachtelete funktionen rein passen. die 2. wird später durch die 1. in
        // confirm-msg-component aufgerufen.
        setOnConfirm(()=> async()=>{

            setSpinnerOpen(true);

            try{
                const deacitivateProjectResponse = await axios({
                    method:"post",
                    url:`${apiUrl}/deactivateProjects`,
                    headers:{
                    'content-type': 'application/json',
                    },
                    data:{ 
                        function:"deactivateProjects",
                        checkedProjects,
                        apiKey:apiKey            
                    }
                });
    
                const isDeacitvated = await deacitivateProjectResponse.data; 
    
    
                if(isDeacitvated){
                    //checked projects leeren.
                    setCheckedProjects([]);

                    setSpinnerOpen(false);

                    //projekte neu laden um aktuell zu halten
                    setAreProjectsUpdated(true);
                }

            }
            catch (error) {
                setSpinnerOpen(false);

                setErrorMsg("Verbindungsfehler! Es konnten keine Projekte deaktiviert werden.");
                setErrorOpen(true);

            }

        });
    }

    /**
     * Die Häufigkeit des Erstellens von Berichten auf AWR-Cloud auf Monatlich umstellen.
     * @returns 
     */
    const onActivateHandler = () => {
        
        //wenn keine projekte ausgewählt sind. funktion terminieren.
        if (areNoProjectsSelected()) return;


        setConfirmOpen(true);
        setConfirmTitle("Projekte aktivieren");
        setConfirmMsg("Sollen die ausgwählten Projekte aktiviert werden?")
        //hier 2 verschachtelete funktionen rein passen. die 2. wird später durch die 1. in
        // confirm-msg-component aufgerufen.
        setOnConfirm(()=> async()=>{

            setSpinnerOpen(true);

            try{
                const acitivateProjectResponse = await axios({
                    method:"post",
                    url:`${apiUrl}/activateProjects`,
                    headers:{
                    'content-type': 'application/json',
                    },
                    data:{ 
                        function:"activateProjects",
                        checkedProjects,
                        apiKey:apiKey            
                    }
                });
        
                const isActivated = await acitivateProjectResponse.data; 
    
                if(isActivated){
                    //checked projects leeren.
                    setCheckedProjects([]);

                    setSpinnerOpen(false);
    
                    //projekte nach Änderungen neu abfragen.
                    setAreProjectsUpdated(true);
                }

            }
            catch (error) {
                setSpinnerOpen(false);

                setErrorMsg("Verbindungsfehler! Es konnten keine Projekte aktiviert werden.");
                setErrorOpen(true);

            }

        });

    }


    /**
     * ondemand Berichte anfordern.
     */
    const onRequestOndemandHandler = async () => {

        //wenn keine projekte ausgewählt sind. funktion terminieren.
        if (areNoProjectsSelected()) return;

        setConfirmOpen(true);
        setConfirmTitle("Ondemand-Berichte anfordern");
        setConfirmMsg("Sollen Ondemand-Berichte für die ausgwählten Projekte angefordert werden?")

        //hier 2 verschachtelete funktionen rein passen. die 2. wird später durch die 1. in
        // confirm-msg-component aufgerufen.
        setOnConfirm(()=> async()=>{

            setSpinnerOpen(true);

            try{
                const requestOndemandResponse = await axios({
                    method:"post",
                    url:`${apiUrl}/requestOndemand`,
                    headers:{
                    'content-type': 'application/json',
                    },
                    data:{ 
                        function:"requestOndemand",
                        checkedProjects,
                        apiKey:apiKey         
                    }
                });

                const response =  requestOndemandResponse.data; 
    
                // hier on demand fehler behandlung 
                if(response){
                    let responseMsgs = [];

                    const affectedProjects = response.websites;

                    for(let affectedProject in affectedProjects) {
                        // affectedProjects.forEach((affectedProject)=>{

                        const responseCode = affectedProjects[affectedProject].response_code;
                        const projectName = affectedProjects[affectedProject].project;
                        let msg = `Ondemand-Bericht Für Projekt: (${projectName}) war `;
                        switch (responseCode) {
                            case 0:
                                msg += "erfolgreich."
                                break;
                            case 1:
                                msg += "nicht möglich. LIMIT ERREICHT";
                                break;
                            case 2:
                                msg += "nicht möglich. EIN BERICHT BEREITS IN BEARBEITUNG";
                                break;
                            case 5:
                                msg += "fehlerhaft. UNBEKANNTER AWR-Fehler";
                                break;
                            case 6:
                                msg += "nicht möglich. ONDEMAND-OPTION EXISTIERT NICHT";
                                break;
                            case 100:
                                msg += "nicht möglich. FÜR HEUTE GIBT ES SCHON EINEN BERICHT";
                                break;
                        }

                        responseMsgs.push(msg);
                    };
                    setSpinnerOpen(false);

                    responseMsgs.push(<strong>Hinweis: Ondemand-Berichte können paar Minuten, aber auch bis 12 Std. in Anspruch nehmen, bis sie zur Verfügung stehen.</strong>)

                    setInfoMsg(responseMsgs);
                    setInfoOpen(true);
                    
                    
                    //checked projects leeren.
                    setCheckedProjects([]);
                }

            } 
            catch (error) {
                setSpinnerOpen(false);

                setErrorMsg("Verbindungsfehler! Es konnten keine ondemand Berichte angefordert werden.");
                setErrorOpen(true);

            }

        });

    }


    
    /**
     * Alle Projekte, die auf AWR-Cloud vorhanden sind, vom Backend abfragen.
     */
    const getAllProjects = async () => {

        // wenn Änderungen auf Projekte angewendet sind, Projekte neu abfragen.
        if(areProjectsUpdated){

            try {
                const allProjects = await axios({
                    url:`${apiUrl}/getAllProjects`,
                    method:"post",
                    headers:{'Content-Type': 'application/json'},
                    data:{ 
                        function:"getAllProjects",
                        apiKey:apiKey        
                    },    
                    onDownloadProgress:downloadProgressHandler
                });

                setProjectsList(await allProjects.data);
                setFilteredProjectList(await allProjects.data);

                //Projekte in localstorage cachen.
                localStorage.setItem("allProjects", JSON.stringify(allProjects.data));
        
                //um Projekte später aus cache zu laden
                setAreProjectsUpdated(false);

            } catch (error) {
                setConfirmTitle("Verbindungs Fehler");
                setConfirmMsg("Es konnten keine Daten vom Backend gelden werden! Nochmal versuchen?");
                setConfirmOpen(true);
                setOnConfirm(()=> ()=>{
                    getAllProjects();
                })
    
                console.log(error)
            }    
            
        }
        // wenn keine Änderungen auf Projekte angewendet sind, Projekte vom cache übernehmen.
        else{

            const cachedProjects = JSON.parse(localStorage.getItem("allProjects"));
            
            setProjectsList(cachedProjects);
            
            //spinner ausblenden.
            setLoadProgress(100);

        }

    }
    

    
    //--------------------------------------------------------------------------
    //              Helfer-Funktionen
    //--------------------------------------------------------------------------

    //Überprüfen ob Projekte ausgewählt sind.
    const areNoProjectsSelected = () => {

        if(checkedProjects.length < 1){
            setInfoMsg("Keine Projekte ausgewählt!")
            setInfoOpen(true);
            return true;
        }
    }

    // funktioniert nicht wie es von einem Progress zu erwarten ist, aber liefert 100 wenn es fertig ist.
    const downloadProgressHandler = (progressEvent) => {

        const percentCompleted = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
        );

        setLoadProgress(percentCompleted);
    }



    //--------------------------------------------------------------------------
    //              Suchen und Filtern
    //--------------------------------------------------------------------------
    const onSearch = (event) => {

        const searchTxt = event.target.value.toLowerCase();
        setSearchedText(searchTxt); 

        //bei der Suche seitenindex wieder auf 0 setzen
        setStartChunk(0);
        setChunkToRender(0);
    }

        
    // Projects nach such-text filtern.
    const filterProjects = () => {

        const filterdProjects = projectsList.filter((project)=>{
            return project.name.toLowerCase().includes(searchedText) ||
                project.customerId.includes(searchedText) ||
                project.billId.includes(searchedText) ||
                project.type.toLowerCase().includes(searchedText) ||
                project.frequency.toLowerCase().includes(searchedText) ||
                project.id == searchedText;
        })

        setFilteredProjectList(filterdProjects);
    }
    


    //--------------------------------------------------------------------------
    // Suchergebnisse auf mehreren Seiten aufteilen (Effinzienter bei der Suche)
    //--------------------------------------------------------------------------

    //wenn auf index-nummer geklickt wird, diese anzeigen
    const indexClickHandler = (index,event) => {
        setChunkToRender(index);
    }

    //die nächsten 10 Seitennumerierungen anzeigen.
    const showNextChunks = ()=>{
        if(chunksCount-(startChunk+10) > 0){
            setStartChunk(startChunk + 10)
        }
    }

    //die vorherigen 10 Seitennumerierungen anzeigen.
    const showPreviousChunks = ()=>{
        if((startChunk-10) >= 0){
            setStartChunk(startChunk - 10)
        }
    }


    return(
        <div className="projects-overview-container">
            <div className="search-container">

                <div className="search-field-wrapper">
                    <FormInput
                        type="search"
                        id="search-field"
                        label="Nach Projekten suchen"
                        value={searchedText}
                        onChange={onSearch}
                        isValid={filteredProjectList.length > 0}
                    ></FormInput>
                </div>

                {searchedText&&
                    <span className="search-results-count">{filteredProjectList.length} passende Ergebnisse</span>
                }

            </div>

            <ProjectList 
                loadProgress={loadProgress}
                chunkedProjectsArray={chunkedProjectsArray}
                chunkToRender={chunkToRender}
                indexClickHandler={indexClickHandler}
                showNextChunks={showNextChunks}
                showPreviousChunks={showPreviousChunks}
                startChunk={startChunk}
                projectsCount={projectsList.length}
            />

            <div className="control-bar">

                {/* nur Benutzern mit admin-rechten anzeigen */}
                {isLoggedIn && userData.isAdmin === 1 &&
                    <Button color="danger"
                            outline
                            className={"del-selected-projects"}
                            title="Die ausgewählen Projekte inkl. Berichte löschen!"
                            onClick={onDeleteHandler}

                    >
                        Entfernen
                    </Button> 
                }

                <Button color="secondary"
                        outline 
                        className={"deactivate-selected-projects"}
                        title="Es wird die Häufigkeit von ausgewählten Projekten auf ondemand umgestellt!"
                        onClick={onDeactivateHandler}
                >
                    Deaktivieren
                </Button>

                <Button color="success"
                        outline
                        className={"activate-selected-projects"}
                        title="Es wird die Häufigkeit von ausgewählten Projekten auf monthly umgestellt!"
                        onClick={onActivateHandler}
                >
                    Aktivieren
                </Button>

                <Button color="dark"
                        outline 
                        className={"request-ondemand"}
                        title="Es kann paar Minuten bis stunden dauern, bis die Berichte zur Verfügung stehen!"
                        onClick={onRequestOndemandHandler}
                >
                    Ondemand-Bericht anfordern
                </Button>


                <Button color="primary"
                        outline 
                        className="add-new-project"
                        onClick={()=>{navigate("/create-project")}}
                >
                    Neues Projekt anlegen
                </Button>

            </div>

        </div>
    );

}

export {ProjectsOverview};