import { useContext } from "react";
import { createContext, useEffect, useState } from "react";
import { SingleProjectOverviewContext } from "./single-project-overview.context.jsx";
import axios from "axios";
import { GlobalVariablesContext } from "./global-variables.context.jsx";


export const ReportsListContext = createContext({
    openReportsList:false,
    setOpenReportsList: ()=>{},
    availableReportsDates: [],
    setAvailableReportsDates: ()=>{},
    sortReports: ()=>{}
})



export const ReportsListProvider = ({children}) => {
   
    const [openReportsList,setOpenReportsList] = useState(false);
    const [availableReportsDates,setAvailableReportsDates] = useState([]);
    
    const {projectToView} = useContext(SingleProjectOverviewContext)
    const {apiUrl,apiKey} = useContext(GlobalVariablesContext);

    useEffect(()=>{
        
        //Nur dann ausführen wenn projectToView nicht leer ist.
        if(projectToView.id !== ""){
            
            const getAvailableRportsDates = async () => {
    
                const availableRportsDates = await axios({
                    method:"post",
                    url:`${apiUrl}/getReportsDates`,
                    headers:{
                        'content-type': 'application/json',
                    },
                    data:{ 
                        function:"getReportsDates",
                        projectToView,
                        apiKey:apiKey            
                    }
                    
                });
                
                // Nur wenn das Eegebniss ein Array ist, availableReportsDates zuweisen.
                if(Array.isArray(availableRportsDates.data)){
                    setAvailableReportsDates(availableRportsDates.data);
                }
            }
    
            getAvailableRportsDates();
    
        }

    },[projectToView]);


    /**
    * Berichte auf- oder absteigend sortieren.
    */
    const sortReports = () => {
        //Problem: reverse() zeigt auf das selbe Array in Memory. deswegen enteckt react keine Änderung um neu zu rendern.
        //Lösung: mit new Array wird ein neues Array erstellen das neue Referenz in memory hat.
        const sortedReports = new Array(...availableReportsDates.reverse());
        setAvailableReportsDates(sortedReports); 
    }
    
    const value = {
        openReportsList,
        setOpenReportsList,
        availableReportsDates,
        setAvailableReportsDates,
        sortReports
    };

    return (
        <ReportsListContext.Provider value={value}>
            {children}
        </ReportsListContext.Provider>
    )
}