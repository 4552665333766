import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from "reactstrap";
import { useState, useContext } from "react";
import { Information } from "../../contexts/info-msg.context.jsx";
import {CheckedProjectsListItem} from "../checked-projects-list-item/checked-projects-list-item.component.jsx";

import "./checked-projects-list.styles.scss";
import { CheckedProjectsContext } from "../../contexts/checked-projects.context.jsx";

export const CheckedProjectsList = () => {

    //contexts
    const {checkedProjects,setCheckedProjects,openCheckedProjectsList,setOpenCheckedProjectsList,resetCheckedProjects} = useContext(CheckedProjectsContext);


  return (
    <div>
        <Modal
            fullscreen={false}
            size="lg"
            toggle={function noRefCheck(){setOpenCheckedProjectsList(false)}}
            isOpen={openCheckedProjectsList}
            centered={true}
        >
            <ModalHeader toggle={()=>{setOpenCheckedProjectsList(false)}} className="checked-projekts-modal-header" >
                Ausgewählte Projekte
            </ModalHeader>

            <ModalBody>
                <div className="checked-projects-list-header">
                    <h4>ID</h4>
                    <h4>Name</h4>
                </div>
                <div className="checked-projects-list-body">
                    {checkedProjects.length < 1 &&
                        <span id="checked-list-is-empty">Keine Projekte angehakt !</span>
                    }

                    {
                        checkedProjects.map((checkedProject,index)=>{
                            return(
                                <CheckedProjectsListItem key={index} checkedProject={checkedProject}/>
                            )
                        })
                    }
                </div>
            </ModalBody>

            <ModalFooter>
                <Button color="warning" outline onClick={resetCheckedProjects}>
                    Liste leeren
                </Button>

                <Button color="danger" onClick={() => {setOpenCheckedProjectsList(false)}}>
                    Schließen
                </Button>
            </ModalFooter>
        </Modal>
    </div>
  )
}


