import { useContext } from "react";
import { createContext, useEffect, useState } from "react";
import { SingleProjectOverviewContext } from "./single-project-overview.context.jsx";
import axios from "axios";



export const SignupNewUserContext = createContext({
    openSignupNewUser:false,
    setOpenSignupNewUser: ()=>{},
    // availableReportsDates: [],
    // setAvailableReportsDates: ()=>{}
})



export const SignupNewUserProvider = ({children}) => {
   
    const [openSignupNewUser,setOpenSignupNewUser] = useState(false);

    
    const value = {
        openSignupNewUser,
        setOpenSignupNewUser,
    };

    return (
        <SignupNewUserContext.Provider value={value}>
            {children}
        </SignupNewUserContext.Provider>
    )
}