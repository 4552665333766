import "./spinner.styles.scss";


const renderSpinner = (type)=>{

  switch (type) {
    case 1:
      return(
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )

    case 2:
      return(
        <div className="pl pl-kinetic"></div>
      ) 

    case 3:
      return(
        <div className='center'>
          <div className='loader'></div>
        </div>
      )  
    case 4:
      return(
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      )
       
    default:
      return(
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
      )
  } 

}

const Spinner = ({type}) => {
  return (
    <>
    {
      renderSpinner(type)
    }
    </>
  )
}


export {Spinner};