import { useState } from 'react';
import axios from 'axios';
import {ProjectForm} from '../../components/project-form/project-form.component.jsx';
import './create-project.route.styles.scss';
import { useContext } from 'react';
import { SpinnerContext } from '../../contexts/spinner.context.jsx';
import { ErrorMsgContext } from '../../contexts/error-msg.context.jsx';
import {NativeButton} from '../../components/native-button/native-button.component.jsx';
import { useNavigate } from 'react-router-dom';
import { Confirmation } from '../../contexts/confirmation.context.jsx';


const CreateProject = () => {    

    const [isCreateProjectsFromEmpty, setIsCreateProjectsFromEmpty] = useState(true);

    const{ setConfirmTitle, setConfirmMsg, setConfirmOpen, setOnConfirm} = useContext(Confirmation);
    const navigate = useNavigate();


    const onClose = () => {

        if(!isCreateProjectsFromEmpty){
            
            setConfirmTitle("Nicht gespeicherte Daten");
            setConfirmMsg("Alle Eingegebenen daten werden verworfen. Trotzdem schlißen?")
            setConfirmOpen(true);

            //hier 2 verschachtelete funktionen rein passen. die 2. wird später 
            // durch die 1. in confirm-msg-component aufgerufen.
            setOnConfirm(()=> async()=>{
                setIsCreateProjectsFromEmpty(true);
                navigate("/")
            });

            //funktion hier abbrechen
            return;
        }

        navigate("/")

    }



    return (
        <div className="create-project-form-container">

            <div className="create-project-box-header">

                <h1 className='create-project-box-header-title'>Neues Projekt erstellen</h1>

                <NativeButton 
                    className="create-project-box-close-btn" 
                    buttonType="close" 
                    onClick={onClose}
                    title="Schließen"
                >X</NativeButton>

            </div>

            <ProjectForm isFormEmpty = {setIsCreateProjectsFromEmpty}/>

        </div>
    )
}

export {CreateProject};