
import { useContext, useEffect, useState } from "react";
import { CheckedProjectsContext } from "../../contexts/checked-projects.context.jsx";
import { ProjectsStatusContext } from "../../contexts/projects-status.context.jsx";
import {NativeButton} from "../native-button/native-button.component.jsx";
import "./project-list-item.styles.scss";
import { useNavigate } from "react-router-dom";
import { SingleProjectOverviewContext } from "../../contexts/single-project-overview.context.jsx";

const ProjectListItem = ({project}) => {
    const [isChecked, setIsChecked] = useState (false);

    const {id,name,customerId,billId,type,frequency} = project;

    const navigate = useNavigate();

    //Contexts
    const {checkedProjects, setCheckedProjects} = useContext(CheckedProjectsContext);
    const {setProjectToView, setUneditable} = useContext(SingleProjectOverviewContext);


    const onOpenSingleProject = () => {

        setProjectToView(project);
        navigate("/single-project-overview");
    }

    //wenn checkedProjects gelert wird, checkboxes wieder auf false setzen.
    useEffect(()=>{
        if(checkedProjects.length < 1)
        setIsChecked(false)
    },[checkedProjects])


    //Bei jedem Rendern überprüfen ob ein projekt im checklist ist das hacken einsetzen, sonst Hacken absetzen.
    useEffect(()=>{
        const searchProject = checkedProjects.filter((checkedProject)=>{
            return checkedProject.id === project.id;
        });

        if(searchProject.length !== 0){
            setIsChecked(true);
        }
        else{
            setIsChecked(false);
        }
 
    })


    const checkboxChangeHandler = (e) => {
        //Wenn die checkbox neben Project gecheckt ist, das Projekt zu checkedProjects hinzufügen,
        // und wenn nicht dieses entfernen.
        if(e.target.checked){
            setCheckedProjects([...checkedProjects,project])
        }
        else{
            const newCheckedProjects = checkedProjects.filter(checkedProject => {
                return checkedProject.id !== project.id;
            })
            setCheckedProjects(newCheckedProjects);
        }

        // isChecked togglen
        setIsChecked(!isChecked);
    }




    return (
        <tr className="project-list-element">
            <td>
                <input type="checkbox" checked={isChecked} className="projects-list-check-box" onChange={checkboxChangeHandler}/>
            </td>

            <td>
                <span className="id">{id}</span>
            </td>

            <td>
                <span className="name">{name}</span>
            </td>

            <td>
                <span>{customerId}</span>
            </td>

            <td>
                <span>{billId}</span>
            </td>

            <td>
                <span>{type}</span>
            </td>

            <td>
                <span className="frequency">{frequency}</span>
            </td>

            <td>
                <NativeButton buttonType={"enter"} title="Projekt öffnen" onClick={onOpenSingleProject}>&crarr;</NativeButton>
            </td>

        </tr>
    )
}

export {ProjectListItem};