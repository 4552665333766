import { createContext, useEffect, useState } from "react";



export const ErrorMsgContext = createContext({
    errorOpen:false,
    setErrorOpen: ()=>{},
    errorMsg:"",
    setErrorMsg: ()=>{},
})



export const ErrorMsgContextProvider = ({children}) => {
   
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);


    const value = {errorOpen, setErrorOpen, errorMsg, setErrorMsg};

    return (
        <ErrorMsgContext.Provider value={value}>
            {children}
        </ErrorMsgContext.Provider>
    )
}