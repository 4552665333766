// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checked-projekts-modal-header{background-color:#d3d3d3}.checked-projects-list-header{display:grid;grid-template-columns:10% 80% 10%;border-bottom:solid 2px gray;margin-bottom:10px}.checked-projects-list-body{overflow-y:scroll;max-height:50vh}.checked-projects-list-body :nth-child(odd).checked-projects-list-item{background-color:#d3d3d3}.checked-projects-list-body :nth-child(even).checked-projects-list-item{background-color:#9b9999}.checked-projects-list-body #checked-list-is-empty{display:block;text-align:center;font-size:large;font-weight:bold;color:#f76060}", "",{"version":3,"sources":["webpack://./src/components/checked-projects-list/checked-projects-list.styles.scss"],"names":[],"mappings":"AAEA,+BACI,wBAAA,CAGJ,8BACI,YAAA,CACA,iCAAA,CACA,4BAAA,CACA,kBAAA,CAGJ,4BAEI,iBAAA,CACA,eAAA,CAEI,uEACI,wBAAA,CAIJ,wEACI,wBAAA,CAIR,mDACI,aAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA","sourcesContent":["@import 'bootstrap/dist/css/bootstrap.min.css';\n\n.checked-projekts-modal-header{\n    background-color: lightgrey;\n}\n\n.checked-projects-list-header{\n    display: grid;\n    grid-template-columns: 10% 80% 10%;\n    border-bottom: solid 2px gray ;\n    margin-bottom: 10px;\n}\n\n.checked-projects-list-body{\n\n    overflow-y: scroll;\n    max-height: 50vh;\n    :nth-child(odd){\n        &.checked-projects-list-item{\n            background-color: lightgray;\n        }\n    }\n    :nth-child(even){\n        &.checked-projects-list-item{\n            background-color: rgb(155, 153, 153);\n        }\n    }\n\n    #checked-list-is-empty{\n        display: block;        \n        text-align: center;\n        font-size: large;\n        font-weight: bold;\n        color: rgb(247, 96, 96);\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
