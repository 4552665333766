import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from "reactstrap";
import { useState, useContext, useEffect } from "react";
import { ReportsListContext } from "../../contexts/reports-list.context.jsx";
import {ReportsListItem} from "../reports-list-item/reports-list-item.component.jsx";

import "./reports-list.styles.scss";


const ReportsList = () => {

    //contexts
    const {openReportsList,setOpenReportsList,availableReportsDates,sortReports} = useContext(ReportsListContext);


    return (
        <div>
            <Modal
                fullscreen={false}
                size="lg"
                toggle={()=>{setOpenReportsList(false)}}
                isOpen={openReportsList}
                centered={true}
            >
                <ModalHeader toggle={()=>{setOpenReportsList(false)}} className="reports-list-modal-header" >
                    Verfügbare Berichte
                </ModalHeader>

                <ModalBody>
                    <div className="reports-list-header">
                        <h4>Nr.</h4>
                        <h4>Datum</h4>
                        <Button 
                            className="sort-btn" 
                            color=""
                            size="sm"
                            onClick={sortReports}
                            title="Berichte ab- oder aufsteigend sortieren"
                        >
                            <img width="25px" src={require ("../../assets/imgs/sort.png")} alt="sort" />
                        </Button>
                    </div>
                    <div className="reports-list-body">
                        {availableReportsDates.length < 1 &&
                            <span id="reports-list-is-empty">Keine Berichte Vorhanden!</span>
                        }

                        {
                            availableReportsDates.map((availableReport,index)=>{
                                return(
                                    <ReportsListItem key={index} index={availableReport.index} reportDate={availableReport.date}/>
                                )
                            })
                        }
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button color="danger" onClick={() => {setOpenReportsList(false)}}>
                        Schließen
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}


export {ReportsList};