import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from "reactstrap";
import { useState, useContext } from "react";
import { Information } from "../../contexts/info-msg.context.jsx";

import "./info-msg.styles.scss";

const InfoMsg = () => {

    //contexts
    const {infoOpen,setInfoOpen,infoMsg} = useContext(Information);


  return (
    <div>
        <Modal
            fullscreen={false}
            size="lg"
            toggle={function noRefCheck(){setInfoOpen(false)}}
            isOpen={infoOpen}
            centered={true}
        >
            <ModalHeader toggle={function noRefCheck(){setInfoOpen(false)}}>
                Information
            </ModalHeader>

            <ModalBody className="info-msg-modal-body">
                {Array.isArray(infoMsg)
                    ?infoMsg.map((msg,index) => {
                        return <p key={index}>{msg}</p>;
                    })
                    :infoMsg
                }
            </ModalBody>

            <ModalFooter>
                <Button onClick={function noRefCheck(){setInfoOpen(false)}}>
                    Schließen
                </Button>
            </ModalFooter>
        </Modal>
    </div>
  )
}


export {InfoMsg};