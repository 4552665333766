import { createContext, useEffect, useState } from "react";



export const SpinnerContext = createContext({
    spinnerOpen:false,
    setSpinnerOpen: ()=>{},
})



export const SpinnerContextProvider = ({children}) => {
   
    const [spinnerOpen, setSpinnerOpen] = useState(false);

    const value = {spinnerOpen, setSpinnerOpen};

    return (
        <SpinnerContext.Provider value={value}>
            {children}
        </SpinnerContext.Provider>
    )
}