import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from "reactstrap";
import { useState, useContext } from "react";
import { SpinnerContext } from "../../contexts/spinner.context.jsx";

import "./spinner-modal.styles.scss";
import {Spinner} from "../spinner/spinner.component.jsx";

const SpinnerModal = () => {

    //contexts
    const {spinnerOpen, setSpinnerOpen} = useContext(SpinnerContext);


  return (
        <Modal
            fullscreen={false}
            size="sm"
            toggle={function noRefCheck(){}}
            isOpen={spinnerOpen}
            centered={true}
            className="spinner-modal"
        >

            <ModalBody>
                <Spinner></Spinner>
            </ModalBody>

        </Modal>
  )
}


export {SpinnerModal};