import './project-form.styles.scss';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import axios from "axios";
import { Confirmation } from '../../contexts/confirmation.context.jsx';
import { SpinnerContext } from '../../contexts/spinner.context.jsx';
import {FormInputMultipleValues} from '../form-input-multiple-values/form-input-multiple-values.component.jsx';
import {FormInput} from '../form-input/form-input.component.jsx';
import {FormSelect} from '../form-select/form-select.component.jsx';
import { ErrorMsgContext } from '../../contexts/error-msg.context.jsx';
import { useNavigate } from 'react-router-dom';
import { SingleProjectOverviewContext } from '../../contexts/single-project-overview.context.jsx';
import { ReportsListContext } from '../../contexts/reports-list.context.jsx';
import { ProjectsStatusContext } from '../../contexts/projects-status.context.jsx';
import { GlobalVariablesContext } from '../../contexts/global-variables.context.jsx';
import punycode from "punycode/punycode.js";

const ProjectForm = ({ className, isProjectOverview, updateProject, isFormEmpty,...otherProps }) => {


    let defaultFormInputs = {
        name:"",
        customerId:"",
        billId:"",
        type:"",
        depth:"",
        frequency:"",
        keyword:"",
        website:""
    }


    const defaultFormInputsValidation = {
        name:true,
        customerId:true,
        billId:true,
        type:true,
        depth:true,
        frequency:true,
        keyword:true,
        website:true
    }
    

    const [formInputs,setFormInputs] = useState(defaultFormInputs);
    const [inputsValidationChecker, setInputsValidationChecker] = useState(defaultFormInputsValidation);
    const [keywords,setKeywords] = useState([]);
    const [websites,setWebsites] = useState([]);

    //contexts
    const {setConfirmOpen,setConfirmTitle,setConfirmMsg,setOnConfirm} = useContext(Confirmation);
    const {setSpinnerOpen} = useContext(SpinnerContext);
    const {setErrorOpen,setErrorMsg} = useContext(ErrorMsgContext);
    const { setOpenReportsList } = useContext(ReportsListContext);
    const { setAreProjectsUpdated } = useContext(ProjectsStatusContext);
    const {apiUrl,apiKey} = useContext(GlobalVariablesContext);

    //navigate
    const navigate = useNavigate();


    //∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇
    // dieser Teil behandelt single-project-overview
    //∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇

    const {projectToView,setProjectToView,projectToViewKeywords,projectToViewWebsites,isUneditable,setIsUneditable} = useContext(SingleProjectOverviewContext);

    //nur beim ersten rendern.
    //wenn form aus single-project-overview aufgerufen wird, Felder erstmal unbearbeitbar machen.
    useEffect(()=>{
        if(isProjectOverview){
            setIsUneditable(true);
        }
        else{
            setIsUneditable(false);
        }
    },[]);


    //wenn das formular für single-project-overview bestimmt ist, die Werte vom anzuzeigenden Projekt übernehmen.
    useEffect(()=>{

        if(isProjectOverview && projectToView.name){
            setFormInputs({
                ...setFormInputs,
                ...projectToView, 
                keyword:"",
                website:""
            });
            setKeywords(projectToViewKeywords);
            setWebsites(projectToViewWebsites);
        }

    },[projectToView,projectToViewKeywords,projectToViewWebsites,isUneditable]);
    


    //project aktualisieren
    const onUpdateProject = () => {

        // Validierung der Daten überprüfen.
        if (!areInputsValid()) return;

        // wenn alles valide ist.
        const projectToUpdate = {...formInputs,keywords:keywords,websites:websites}

        setConfirmOpen(true);
        setConfirmTitle("Projekt aktualisieren");
        setConfirmMsg("das Projekt jetzt aktualisieren, und die neuen Daten übernehmen?")
        //hier 2 verschachtelete funktionen rein passen. die 2. wird später durch die 1. in
        // confirm-msg-component aufgerufen.
        setOnConfirm(()=> ()=>{

            updateProject(projectToUpdate);
        });
    }
    

    const onCancelEdit = () => {
        setIsUneditable(true)
        setInputsValidationChecker(defaultFormInputsValidation);
    }


    //ΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔ
    // oberer Teil behandelt single-project-overview
    //ΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔ




    //∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇
    //      dieser Teil behandelt create-project
    //∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇


    useEffect(()=>{
        // wenn das Fromular nicht in single-project-overview angezeigt wird.
        // (also in create-project).
        if(!isProjectOverview){
            //überprüfen ob felder leer sind oder nicht. Das wird in create-project
            //gebraucht, um beim schlißen Confirmation anzeigen oder nicht.
            const {name,customerId,billId,type,depth,frequency} = formInputs;

            if(name.length > 0 || customerId.length > 0 || billId.length > 0 ||
                type.length > 0 || depth.length > 0 || frequency.length ||
                keywords.length > 0 || websites.length > 0 
            ){
                isFormEmpty(false);
            }
            else{
                isFormEmpty(true);
            }
        }
    },[formInputs,keywords,websites]);


    //Projekt erstellen
    const onCreateProject = (event) => {

        // Validierung der Daten überprüfen.
        if (!areInputsValid()) return;

        // wenn alles valide ist.

        setConfirmOpen(true);
        setConfirmTitle("Projekt erstellen");
        setConfirmMsg("Soll das Projekt jetzt erstellt werden?")

        //hier 2 verschachtelete funktionen rein passen. die 2. wird später durch die 1. in
        // confirm-msg-component aufgerufen.
        setOnConfirm(()=> async()=>{

            setSpinnerOpen(true);

            const {name,customerId,billId,type,depth,frequency} = formInputs;
            const projectToBeCreated = {
                name:name,
                customerId:customerId,
                billId:billId,
                type:type,
                depth:depth,
                frequency:frequency,
                keywords:keywords,
                websites:websites
            }


            try{
                const createProjectResponse = await axios({
                    method:"post",
                    url:`${apiUrl}/createProject`,
                    headers:{
                    'content-type': 'application/json',
                    },
                    data:{ 
                        function:"createProject",
                        projectToBeCreated,
                        apiKey:apiKey            
                    }
                });

                const isCreated = await createProjectResponse.data; 
    
    
                if(isCreated){

                    setSpinnerOpen(false);
                    if(isCreated.response_code === 20){
                        //Projekt mit dem Namen existiert schon.
                        setErrorMsg("Projekt mit diesem Namen existiert schon!")
                        setErrorOpen(true)
                        setInputsValidationChecker({...inputsValidationChecker,name:false})
                    }
                    else if(isCreated === true){
                        //Felder zurücksetzen.
                        setFormInputs(defaultFormInputs);
                        setKeywords([]);
                        setWebsites([]);

                        //um beim zurückkehren zur Projekten-Übersicht, 
                        //Projekte neu abzufragen, und nicht die gecacheten anzeigen.
                        setAreProjectsUpdated(true);

                        //User benachrichtigen.
                        setConfirmTitle("Erfolgreich");
                        setConfirmMsg("Projekt wurde erfolgreich erstellt. Soll es zur Projekten-Übersicht navigiert werden?");
                        setConfirmOpen(true);
                        setOnConfirm(()=>()=>{
                            navigate("/");
                        })
                    }
                }

            }
            catch (error) {
                setSpinnerOpen(false);

                setErrorMsg("Verbindungsfehler! Das Projekt konnte nicht erstellt werden.");
                setErrorOpen(true);
            }

        });

    };

    //ΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔ
    //      oberer Teil behandelt create-project
    //ΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔΔ



    //∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇
    //                  algemeines
    //∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇∇

    //nur Zahlen erlauben.
    const allowOnlyNums = (e)=>{
        if(e.target.type === "tel" && !/[0-9]/.test(e.key))  e.preventDefault();
    }

    const onInputValueChanged = (event) => {
        event.preventDefault();
        
        const {name,value} = event.target;

        //input wieder als valide umsetzen. wenn neuer input nicht leer ist
        // bei website muss zusätlich wieder geprüft wird ob domain gultig ist.
        if(name === "website"){
            if(checkWebsiteValidate(value.trim())){
                setToValid("website");
            }
        }
        else{
            setToValid(name);
        }

        //den neuen Wert übernehmen.
        setFormInputs({...formInputs, [name]:value})
    }


    const MultipleInputHandler = (event) => {

        const {value, name} = event.target;
        
        // add keyword on Enter 
        if (event.key === "Enter" && name === "keyword"){

            //nur dann hinzufügen, wenn keyword noch nicht vorhanden, und nicht leer ist.
            //außerdem in Kleinbuchstaben umwandeln 
            if (!keywords.includes(value.trim()) && value.trim().length > 0){

                setKeywords([...keywords,value.trim().toLowerCase()]);
            }
            setFormInputs({...formInputs,keyword:""});
        }

        //add website on Space
        else if (event.charCode === 32 && name === "website"){
            
            //Remove www from the domain.
            const filteredDomain = value.trim().split('.').filter((part,index) => index===0?part.toLowerCase() !== "www":true);
            const valueWithoutWWW = filteredDomain.join('.');

            //website in ACE umwandeln Falls als IDN eingetragen wurde.
            const websiteInACE = punycode.toASCII(valueWithoutWWW);

            //überprüfen ob domain valide ist
            if(checkWebsiteValidate(websiteInACE.trim())){
                //nur dan hinzufügen, wenn website noch nicht vorhanden ist.
                //außerdem in Kleinbuchstaben umwandeln 
                if(!websites.includes(websiteInACE.trim())){

                    setWebsites([...websites,websiteInACE.trim().toLowerCase()]);
                }
                setFormInputs({...formInputs,website:""});
                setInputsValidationChecker({...inputsValidationChecker,website:true})
            }
            else{
                setFormInputs({...formInputs,website:websiteInACE.trim()});
                setInputsValidationChecker({...inputsValidationChecker,website:false})
            }
        }
    }

    //einen einzigen wert aus Liste entfernen.
    const deleteFromMultipleInputValues = (index,name) => {

        if(name=="keyword"){
            // zu entfernenden Wert von den Alten auswerfen.
            const newKeywordsArray = keywords.filter((_,indx) => {
                return indx !== index
            })
            setKeywords(newKeywordsArray);
        }
        else if(name=="website"){

            // zu entfernenden Wert von den Alten auswerfen.
            const newWebsitesArray = websites.filter((_,indx) => {
                return indx !== index
            })
            setWebsites(newWebsitesArray);
        }
    }


    const clearKeywords = () => {
        setKeywords([]);
    }

    
    const clearWebsites = () => {
        setWebsites([]);
    }



    /////////////////////////////
    //      Helfer-Funktinen
    /////////////////////////////

    //Feld auf Valide setzen.
    const setToValid = (InputName) => {
        setInputsValidationChecker({...inputsValidationChecker,[InputName]:true})
    } 
    

    const checkWebsiteValidate = (website) => {
        const regex = /(^(((?!\-))(xn\-\-)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn\-\-)?([a-z0-9\-]{1,61}|[a-z0-9\-]{1,30})\.[a-z]{2,}$)/g;
        const res = website.match(regex);

        return res !== null;
    }
      

    //beim Verlieren des Fokus Lerrzeichen von Anfang und Ende entfernen.
    const trimmer = (e)=>{
        const {name,value} = e.target;

        //den neuen Wert übernehmen.
        setFormInputs({...formInputs, [name]:value.trim()})
    }


    /**
     * überprüft ob alle felder valide sind
     * @returns true wenn alles valide ist
     */
    const areInputsValid = () => {

        if(formInputs.name.length<1) return setInputsValidationChecker({...inputsValidationChecker,name:false})
        if(formInputs.customerId.length<1) return setInputsValidationChecker({...inputsValidationChecker,customerId:false})
        if(formInputs.billId.length<1) return setInputsValidationChecker({...inputsValidationChecker,billId:false})
        if(formInputs.type.length<1) return setInputsValidationChecker({...inputsValidationChecker,type:false})
        if(formInputs.depth.length<1) return setInputsValidationChecker({...inputsValidationChecker,depth:false})
        if(formInputs.frequency.length<1) return setInputsValidationChecker({...inputsValidationChecker,frequency:false})
        if(keywords.length<1) return setInputsValidationChecker({...inputsValidationChecker,keyword:false})
        if(websites.length<1) return setInputsValidationChecker({...inputsValidationChecker,website:false})
        
        //wenn alles valide ist.
        return true;
    }
    


    // Render
    return (
        
        <form className={`project-form-container ${className}`} {...otherProps}>
            <FormInput 
                label={"Name"}
                type="text" 
                name="name" 
                value={formInputs.name} 
                onChange={onInputValueChanged}
                onBlur={trimmer}
                isValid={inputsValidationChecker.name}
                disabled={isProjectOverview?true:false}//beim Projekt-Overview den Namen immer deaktivieren. Der Name kann nicht im Nachhinein geändert werden.
            />
            <FormInput 
                label={"KundenNr."}
                type="tel"
                name="customerId" 
                value={formInputs.customerId} 
                onChange={onInputValueChanged}
                onKeyPress={allowOnlyNums}
                isValid={inputsValidationChecker.customerId}
                disabled={isUneditable}
            />
            <FormInput 
                label={"RechnungsNr."}
                type="tel"
                name="billId" 
                value={formInputs.billId} 
                onChange={onInputValueChanged}               
                onKeyPress={allowOnlyNums}
                isValid={inputsValidationChecker.billId}
                disabled={isUneditable}
            />


            <FormSelect 
                name="type" 
                value={formInputs.type} 
                onChange={onInputValueChanged}               
                isValid={inputsValidationChecker.type }
                disabled={isUneditable}
            >
                <option value="" disabled >Typ</option>
                <option value="SUMO">SUMO</option>
                <option value="SEO">SEO</option>
                <option value="Chefarzt">Chefarzt</option>
            </FormSelect>

            <FormSelect 
                name="depth" 
                value={formInputs.depth} 
                onChange={onInputValueChanged}                
                isValid={inputsValidationChecker.depth}
                disabled={isUneditable}
            >
                <option value="" disabled>Suchtiefe</option>
                <option value="10">10 Seiten</option>
                <option value="5">5 Seiten</option>
            </FormSelect>

            <FormSelect 
                name="frequency" 
                value={formInputs.frequency} 
                onChange={onInputValueChanged}                
                isValid={inputsValidationChecker.frequency}
                disabled={isUneditable}
            >
                <option value="" disabled>Häufigkeit</option>
                <option value="monthly">Monatlich</option>
                <option value="daily">Täglich</option>
                <option value="weekly">Wöchentlich</option>
                <option value="biweekly">Alle 2 Wochen</option>
                <option value="ondemand">Auf Anforderung (ondemand)</option>
            </FormSelect>

            <span id="create-project-keywords-input">
                <FormInputMultipleValues 
                    label={"Keywords"} 
                    name="keyword"
                    value={formInputs.keyword} 
                    values={keywords} 
                    onChange={onInputValueChanged}
                    onKeyPress={MultipleInputHandler}              
                    onBlur={trimmer}
                    deleteFromMultiInput={deleteFromMultipleInputValues}
                    clearList={clearKeywords}
                    isValid={inputsValidationChecker.keyword}
                    isDisabled={isUneditable}
                />
            </span>
            <span id='create-project-keywords-input-info-box'>
                Keywords mit Enter-Taste hinzufügen.
            </span>

            <span id="create-project-websites-input">
                <FormInputMultipleValues
                    label={"Websites"} 
                    name="website"
                    value={formInputs.website} 
                    values={websites} 
                    onChange={onInputValueChanged}
                    onKeyPress={MultipleInputHandler}                  
                    onBlur={trimmer}
                    deleteFromMultiInput={deleteFromMultipleInputValues}
                    clearList={clearWebsites}
                    isValid={inputsValidationChecker.website}
                    isDisabled={isUneditable}
                />
            </span>

            <span id='create-project-websites-input-info-box'>
                Websites mit Leer-Taste hinzufügen.<br/><strong>Hinweis: Nur Hauptdomänen eintragen! Subdomains werden automatisch übernommen.</strong> 
            </span>


            {/* nur dann anzeigen, wenn das Formular in create-project verwendet wird */}
            {/* --------------------------------------------------------------------- */}

            {!isProjectOverview&&           
            <span id='create-project-btn'>
                <Button color='primary' onClick={onCreateProject}>Projekt erstellen</Button>
            </span>
            } 

            {/* nur dann anzeigen, wenn das Formular in single-project-overview verwendet wird */}
            {/* ------------------------------------------------------------------------------ */}

            {isProjectOverview&&  
            <div className="single-project-overview-control-panel">

                {/* wenn ubearbeitbar bearbeiten,und wenn bearbeibtbar speichern button
                    anzeigen */}
                {isUneditable
                    ?            
                    <span id='edit-project-btn'>
                        <Button color='primary' onClick={()=>{setIsUneditable(false)}}>Projekt bearbeiten</Button>
                    </span>
                    :
                    <span id='save-changes-btn'>
                        <Button color='success' onClick={()=>{
                            const projectToUpdate = {...formInputs,keywords:keywords,websites:websites}

                            onUpdateProject(projectToUpdate);
                        }}>Änderungen speichern</Button>
                    </span>
                } 

                {/* wenn ubearbeitbar Berichte,und wenn bearbeibtbar Abbrechen button
                    anzeigen */}  
                {isUneditable
                    ?            
                    <span id='show-reports-btn'>
                        <Button color='warning' onClick={()=>setOpenReportsList(true)}>Berichte anzeigen</Button>
                    </span>
                    :
                    <span id='cancel-edit-btn'>
                        <Button color='danger' onClick={onCancelEdit}>Abbrechen</Button>
                    </span>
                }
            </div>         

            
            } 

        </form>

    )
}


export {ProjectForm};