import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from "reactstrap";
import { useState, useContext } from "react";
import { ErrorMsgContext } from "../../contexts/error-msg.context.jsx";


import "./error-msg.styles.scss";

const ErrorMsg = () => {

    //contexts
    const {errorOpen,setErrorOpen,errorMsg} = useContext(ErrorMsgContext);


  return (
    <div>
        <Modal className="error-modal"
            fullscreen={false}
            size="lg"
            toggle={function noRefCheck(){setErrorOpen(false)}}
            isOpen={errorOpen}
            centered={true}
        >
            <ModalHeader toggle={function noRefCheck(){setErrorOpen(false)}}>
                Error
            </ModalHeader>

            <ModalBody>
                {errorMsg}
            </ModalBody>

            <ModalFooter>
                <Button onClick={function noRefCheck(){setErrorOpen(false)}}>
                    Schließen
                </Button>
            </ModalFooter>
        </Modal>
    </div>
  )
}


export {ErrorMsg};