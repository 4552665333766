import axios from "axios"; 
import { createContext, useContext, useEffect, useState } from "react";
import { GlobalVariablesContext } from "./global-variables.context.jsx";

export const SingleProjectOverviewContext = createContext({
    projectToView:{},
    setProjectToView: ()=>{},
    projectToViewKeywords:[],
    setProjectToViewKeywords: ()=>{},
    projectToViewWebsites:[],
    setProjectToViewWebsites: ()=>{},
    isUneditable:true, 
    setIsUneditable: ()=>{}
})



export const SingleProjectOverviewProvider = ({children}) => {
   
    const [projectToView, setProjectToView] = useState({name:"",id:"",});
    const [projectToViewKeywords, setProjectToViewKeywords] = useState([]);
    const [projectToViewWebsites, setProjectToViewWebsites] = useState([]);
    const [isUneditable, setIsUneditable] = useState(false);
   
    const {apiUrl,apiKey} = useContext(GlobalVariablesContext);

    //Nur einmal beim ersten Initiieren überprüfen ob es ein gecachetes projectToView gibt.
    //wenn dann dieses übernehmen.
    useEffect(() => {
        const cachedProjectToView = JSON.parse(localStorage.getItem("projectToView"));

        if(cachedProjectToView && cachedProjectToView.id !== ""){
            setProjectToView(cachedProjectToView);
        }

    }, []);


    //get Keywords and websites, wenn projectToView sich ändert.
    useEffect(()=>{

        //erstmal das zuzeigende Projekt im localStorage cachen, 
        //um bei aktualisierung der Seite das jeweilige Projekt wieder aus cache anzuzeigen.
        localStorage.setItem("projectToView",JSON.stringify(projectToView));


        const getProjectKeywordsAndWebsites = async () => {

            const keywordsAndWebsites = await axios({
                method:"post",
                url:`${apiUrl}/getKeywordsAndWebsites`,
                headers:{
                    'content-type': 'application/json',
                },
                data:{ 
                    function:"getKeywordsAndWebsites",
                    projectToView,
                    apiKey:apiKey          
                }
        
            });
    
            if(keywordsAndWebsites.data){
                setProjectToViewKeywords(keywordsAndWebsites.data.keywords);
                setProjectToViewWebsites(keywordsAndWebsites.data.websites);
            }
        }

        if(projectToView.name){

            getProjectKeywordsAndWebsites();
        }



        //wenn form geschlossen wird alles zurücksetzen.
        const onUnmount = () => {
            setProjectToViewKeywords([]);
            setProjectToViewWebsites([]);
        }

        return ()=>{
            onUnmount();
        }

    },[projectToView])



    const value = {projectToView, setProjectToView, projectToViewKeywords, setProjectToViewKeywords, projectToViewWebsites, setProjectToViewWebsites, isUneditable, setIsUneditable};

    return (
        <SingleProjectOverviewContext.Provider value={value}>
            {children}
        </SingleProjectOverviewContext.Provider>
    )
}