import { createContext, useEffect, useState } from "react";



export const Information = createContext({
    infoOpen:false,
    setInfoOpen: ()=>{},
    infoMsg:"",
    setInfoMsg: ()=>{},
})



export const InformationProvider = ({children}) => {
   
    const [infoOpen, setInfoOpen] = useState(false);
    const [infoMsg, setInfoMsg] = useState();


    const value = {infoOpen, setInfoOpen, infoMsg, setInfoMsg};

    return (
        <Information.Provider value={value}>
            {children}
        </Information.Provider>
    )
}