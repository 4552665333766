import { useEffect, useRef } from 'react';
import './form-select.styles.scss';

const FormSelect = ({isValid,...otherProps}) => {

    //Mit useRef werden die Felder, wenn invalide sind, fukussiert und pink gefärbt.
    const selectRef = useRef();

    useEffect(()=>{
        if(!isValid){
            selectRef.current.focus();
            selectRef.current.style.backgroundColor = "pink"
        }else{
            selectRef.current.style.backgroundColor = null
        }
    },[isValid])
    

    return (
        <select ref={selectRef} className='form-select-field' {...otherProps} style={!isValid?{backgroundColor:"pink"}:{}}></select>
    );
}

export {FormSelect};