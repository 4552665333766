import { Fragment, useContext } from 'react';
import { useState } from 'react';
import { Button } from 'reactstrap';
import {FormInput} from '../../components/form-input/form-input.component.jsx';
import { AuthenticationContext } from '../../contexts/authentication.context.jsx';
import { Information } from '../../contexts/info-msg.context.jsx';
import { Navigate } from "react-router-dom";

import './user-authentication.route.styles.scss';


const Authentication = () => {
    // States
    const [authValues,setAuthValues] = useState({username:"",password:""});
    const [showMsg,setShowMsg] = useState(false);
    const [msg,setMsg] = useState("");
    const [showPassword,setShowPassword] = useState(false);

    // Contexts
    const { login,isLoggedIn } = useContext(AuthenticationContext);

    const onInputValueChanged = (event) => {
        //falsche Daten msg wieder verbergen
        setShowMsg(false);

        const {name,value} = event.target;

        //den neuen Wert übernehmen.
        if(name === "username"){//username in lowercase umwandeln.
            setAuthValues({...authValues, [name]:value.toLowerCase()});
        }else{
            setAuthValues({...authValues, [name]:value});
        }
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        // wenn benutzername oder password kleiner 6 Zeichen ist.
        if(authValues.username.trim().length < 1 || authValues.password.trim().length < 1){
            setMsg("Der Benutzername, und das Passwort dürfen nicht leer sein!")
            setShowMsg(true);
            return;
        }
        
        // wenn username und password stimmen wird eingeloggt. sonst gibt false zurück.
        const isAuthenticatedUser = await login(authValues.username,authValues.password);

        // wenn isAuthenticatedUser false ist.
        if(!isAuthenticatedUser){
            setMsg("Falscher Benutzername oder Falsches Passwort !");
            setShowMsg(true);
        }
    }

    // passwort anzeigen oder verbergen
    const showPasswordToggler = (event) => {
        setShowPassword(event.target.checked);
    }

    const getLastCalledRoute = ()=> {
        return localStorage.getItem("lastCalledRoute");
    }

    return (

        <Fragment>

        {isLoggedIn 
            // bei erfolgreichem Anmelden, wenn lastCalledRoute nicht (/authentication) war dann auf lastCalledRoute weiterleiten.
            // sonst auf home (/) weiterleiten.           
            ?<Navigate to={ getLastCalledRoute() !== "/authentication" ?localStorage.getItem("lastCalledRoute") :"/"} />

            :<div className="authentication-container">
            
                <h2 className='sign-in-headline'>Anmelden</h2>

                <form className='auth-form' onSubmit={onSubmit}>
                    <FormInput 
                        isValid={true} 
                        label={"Benutzer"} 
                        name="username" 
                        value={authValues.username}
                        onChange={onInputValueChanged}
                    />

                    <div className="password-field-wrapper">
                        <FormInput 
                            isValid={true} 
                            label={"Passwort"} 
                            name="password" 
                            value={authValues.password}
                            onChange={onInputValueChanged}
                            type={showPassword?"text":"password"}
                        />

                        <input className='show-password' type="checkbox" title="Passwort anzeigen" onChange={showPasswordToggler}/>

                    </div>

                    {showMsg&&
                        <span className="false-login-msg">
                            {msg}
                        </span>
                    }

                    <Button type='submit' color='primary'>Anmelden</Button>

                </form>

            </div>
        }

        </Fragment>
    )

}


export {Authentication};