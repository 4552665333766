import "./top-bar.styles.scss";
import { ReactComponent as Logo } from "../../assets/imgs/logo.svg";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Fragment, useContext } from "react";
import { AuthenticationContext } from "../../contexts/authentication.context.jsx";
import { Button } from "reactstrap";
import { SignupNewUserContext } from "../../contexts/signup-new-user.context.jsx";






const TopBar = () => {

    const { isLoggedIn, logout, userData } = useContext(AuthenticationContext);
    const {setOpenSignupNewUser} = useContext(SignupNewUserContext);

    const location = useLocation();
    
    return (
        <Fragment>
            <div className="top-bar-container">
                <Logo className="top-bar-logo"/>

                {isLoggedIn&&//nur dann anzeigen wenn eigeloggt wurde.

                    <span className="top-bar-greeting-msg">Hi, <strong>{userData.username}</strong></span>
                }

                <div className="user-management-box">


                    {isLoggedIn && userData.isAdmin === 1 &&//nur Benutzern mit admin-rechten anzeigen
                        <Button 
                        className="add-user-btn" 
                        color="link"
                        size="sm"
                        onClick={()=>{setOpenSignupNewUser(true)}}
                        title="Neuen Benutzer anlegen"
                        >
                            <img width="25px" src={require ("../../assets/imgs/add-user.png")} alt="download-pdf" />
                        </Button>
                        
                    }

                                    
                    {isLoggedIn&&//nur dann anzeigen wenn eigeloggt wurde.

                        <Button 
                        className="log-out-btn" 
                        color="link"
                        size="sm"
                        onClick={logout}
                        title="Abmelden"
                        >
                            <img width="25px" src={require ("../../assets/imgs/log-out.png")} alt="download-pdf" />
                        </Button>
                    }


                </div>
            </div>

            {!isLoggedIn && location.pathname !== "/authentication" ?<Navigate to="/authentication" />:<Outlet/>} 
        </Fragment>
    );
}

export {TopBar};