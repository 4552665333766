// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".spinner-modal .modal-content{background-color:rgba(0,0,0,0);border:none}", "",{"version":3,"sources":["webpack://./src/components/spinner-modal/spinner-modal.styles.scss"],"names":[],"mappings":"AAII,8BACI,8BAAA,CACA,WAAA","sourcesContent":["@import 'bootstrap/dist/css/bootstrap.min.css';\n\n.spinner-modal{\n\n    .modal-content{\n        background-color: transparent;\n        border: none;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
