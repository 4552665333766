import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import {reportWebVitals} from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import { CheckedProjectsProvider } from './contexts/checked-projects.context.jsx';
import { ProjectsStatusProvider } from './contexts/projects-status.context.jsx';
import { ConfirmationProvider } from './contexts/confirmation.context.jsx';
import { InformationProvider } from './contexts/info-msg.context.jsx';
import { SpinnerContextProvider } from './contexts/spinner.context.jsx';
import { ErrorMsgContextProvider } from './contexts/error-msg.context.jsx';
import { SingleProjectOverviewProvider } from './contexts/single-project-overview.context.jsx';
import { ReportsListProvider } from './contexts/reports-list.context.jsx';
import { AuthenticationProvider } from './contexts/authentication.context.jsx';
import { SignupNewUserProvider } from './contexts/signup-new-user.context.jsx';
import { GlobalVariablesContextProvider } from './contexts/global-variables.context.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <GlobalVariablesContextProvider>
    <HashRouter>
      <CheckedProjectsProvider>
        <SingleProjectOverviewProvider>
          <ProjectsStatusProvider>
            <ConfirmationProvider>
              <InformationProvider>
                <SpinnerContextProvider>
                  <ErrorMsgContextProvider>
                    <ReportsListProvider>
                      <AuthenticationProvider>
                        <SignupNewUserProvider>
                            <App />
                        </SignupNewUserProvider>
                      </AuthenticationProvider>
                    </ReportsListProvider>
                  </ErrorMsgContextProvider>
                </SpinnerContextProvider>
              </InformationProvider>
            </ConfirmationProvider>
          </ProjectsStatusProvider>
        </SingleProjectOverviewProvider>
      </CheckedProjectsProvider>
    </HashRouter>
  </GlobalVariablesContextProvider>

  //</React.StrictMode>
);
