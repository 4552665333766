import axios from "axios";
import { Fragment, useEffect, useState, useContext } from "react";
import { Button } from "reactstrap";
import { CheckedProjectsContext } from "../../contexts/checked-projects.context.jsx";
import { ProjectsStatusContext } from "../../contexts/projects-status.context.jsx";
import {CheckedProjectsList} from "../checked-projects-list/checked-projects-list.component.jsx";
import {PageIndexItem} from "../page-index/page-index-item.component.jsx";
import {ProjectListItem} from "../projects-list-item/project-list-item.component.jsx";
import {NativeButton} from "../native-button/native-button.component.jsx";
import {Spinner} from "../spinner/spinner.component.jsx"
import "./project-list.styles.scss"

const ProjectList = ({
    loadProgress,
    chunkedProjectsArray,
    chunkToRender,
    indexClickHandler,
    showNextChunks,
    showPreviousChunks,
    startChunk,
    projectsCount
}) => {


    // contexts

    const { checkedProjects, setOpenCheckedProjectsList } = useContext(CheckedProjectsContext);

    //Projekt list in Sub-Fenster öffnen.
    const onOpenCheckedProjektsListHandler = ()=>{
        setOpenCheckedProjectsList(true)
    }


    return(
        <div className="results-container">
            <div className="results-container-header">

                <Button 
                color="light"
                outline
                className="checked-projects-list-btn"
                title="Die ausgewählten Projekte anzeigen und verwalten"
                onClick={onOpenCheckedProjektsListHandler}
                >
                    &equiv;
                </Button>

                <span className="checked-projekts-count">{checkedProjects.length} Projekt/e ausgewählt</span>

                <h1>Projekten-Übersicht</h1>
            </div>

            <table>

                <thead>
                    <tr className="projects-list-table-head">
                        <th></th>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Kundennr.</th>
                        <th>Rechnungsnr.</th>
                        <th>Typ</th>
                        <th>Häufigkeit</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody className="projects-list-table-body">
                    <tr className="spinner-row">
                        <td>
                            {loadProgress != 100&&//nur solange anzeigen bis Projekte geladen werden.
                                <Spinner type={1}></Spinner> 
                            }
                        </td>
                    </tr>
                    { chunkedProjectsArray[chunkToRender]&&//wenn das zu anzeigende Chunk nicht leer ist.
                        chunkedProjectsArray[chunkToRender].map((project,index) => {
                            return (
                                <ProjectListItem key={index} project={project} />
                            )
                        })
                    }
                </tbody>
            </table>
                
            {/* Seitennummerierung der Projekte */}

            <div className="pagination">
                {startChunk - 10 >= 0 && //button nur dann anzeigen, wenn es vorherigen Chunk-Block gibt 
                    <NativeButton buttonType={"paginator"} onClick={showPreviousChunks}>&laquo;</NativeButton>
                }
                {
                    <PageIndexItem 
                        chunkedProjectsArray={chunkedProjectsArray} 
                        indexClickHandler={indexClickHandler}
                        startChunk={startChunk}
                        chunkToRender = {chunkToRender}
                    />
                }
                {chunkedProjectsArray.length - (startChunk+10) > 0 && //button nur dann anzeigen, wenn es nächsten Chunk-Block gibt 
                    <NativeButton buttonType={"paginator"} onClick={showNextChunks}>&raquo;</NativeButton>
                }
            </div>

            <span id="projects-count">Anzahl aller Projekte: {projectsCount}</span>
            {/** CheckedProjects Modal */}
            <CheckedProjectsList />
        </div>
    );

    



}

export {ProjectList};