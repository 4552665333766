import {Modal,ModalBody,ModalHeader,ModalFooter,Button} from "reactstrap";
import { useState, useContext } from "react";
import { Confirmation } from "../../contexts/confirmation.context.jsx";

import "./confirm-msg.styles.scss";

const ConfirmMsg = () => {

    //contexts
    const {confirmOpen, setConfirmOpen, confirmMsg, confirmTitle, onConfirm ,type} = useContext(Confirmation);

    const onConfirmHandler = ()=>{
        //callback aufrufen.
        onConfirm();
        //confirm-msf wieder ausblenden.
        setConfirmOpen(false)
    }

  return (
    <div>
        <Modal
            fullscreen={false}
            size="lg"
            toggle={function noRefCheck(){setConfirmOpen(false)}}
            isOpen={confirmOpen}
            centered={true}
        >
            <ModalHeader toggle={function noRefCheck(){setConfirmOpen(false)}}>

                {confirmTitle}
            </ModalHeader>

            <ModalBody>
                {confirmMsg}
            </ModalBody>

            <ModalFooter>
                <Button
                    color="success"
                    onClick={onConfirmHandler}
                >
                    &nbsp;&nbsp; Ja &nbsp;&nbsp;
                </Button>
                {' '}
                <Button onClick={function noRefCheck(){setConfirmOpen(false)}}>
                &nbsp;Nein&nbsp;
                </Button>
            </ModalFooter>
        </Modal>
    </div>
  )
}


export {ConfirmMsg};