import "./reports-list-item.styles.scss";

import {Button} from "reactstrap";
import { useState, useContext } from "react";
import { ReportsListContext } from "../../contexts/reports-list.context.jsx";
import { SingleProjectOverviewContext } from "../../contexts/single-project-overview.context.jsx";
import axios from "axios";
import { Information } from "../../contexts/info-msg.context.jsx";
import { GlobalVariablesContext } from "../../contexts/global-variables.context.jsx";

const ReportsListItem = ({reportDate,index}) => {

    //contexts
    const {openReportsList,setOpenReportsList,getAvailableReportsDates} = useContext(ReportsListContext);
    const {projectToView} = useContext(SingleProjectOverviewContext);
    const {setInfoMsg, setInfoOpen} = useContext(Information);
    const {apiUrl,apiKey} = useContext(GlobalVariablesContext);

    /**
     * Reports im Browser anzeigen.
     */
    const showReport = async () => {

        const reportRequstData = {
            date:reportDate,
            projectId:projectToView.id,
            customerId:projectToView.customerId,
        };

        const report = await axios({
            method:'post',
            url:`${apiUrl}/getReport`,
            headers:{
                'content-type': 'application/json;charset=UTF-8',
            },
            responseType:'blob',
            data:{ 
                function:"getReport",
                reportRequstData,
                apiKey:apiKey          
            }
        })


        const responseBlob = new Blob([report.data]);

        //versuchen das blob in json zu parsen. Bei Erfolg heißt es das Blob ist ein json, und kein pdf
        try {
            const responseJson = JSON.parse(await responseBlob.text());
            if(!responseJson.isReportReady){
                setInfoMsg("Dieser Bericht wird gerade noch vorbereitet. Versuche es bitte kurz später noch einmal")
                setInfoOpen(true);
            }
    
        } 
        // wenn das blob in json nicht umgewandelt werden kann dann ist das blob ein pdf.
        catch (error) {

            const pdfBlob = new Blob([report.data],{type:'application/pdf;charset=UTF-8'});
            const fileUrl = URL.createObjectURL(pdfBlob); 

            const width = Math.round(window.screen.width / 2);
            const height = Math.round(window.screen.height);

            const pdfWindowParams = `resizable=yes, 
                                     scrollbars=yes, 
                                     toolbar=yes,
                                     location=no,
                                     width=${width},
                                     height=${height}`;

            const showPdfWindow = window.open(fileUrl,"pdf-view-window",pdfWindowParams);


            //Objekt-URL wieder freigeben, Speicherlecks zu vermeiden.
            URL.revokeObjectURL(fileUrl);
        }
    }


    /**
     * Report Herunterladen
     */
    const downloadReport = async () => {

        const reportRequstData = {
            date:reportDate,
            projectId:projectToView.id,
            customerId:projectToView.customerId,
        };

        const report = await axios({
            method:'post',
            url:`${apiUrl}/getReport`,
            headers:{
                'content-type': 'application/json;charset=UTF-8',
            },
            responseType:'blob',
            data:{ 
                function:"getReport",
                reportRequstData,
                apiKey:apiKey         
            }
        })

            const responseBlob = new Blob([report.data]);

        //versuchen das blob in json zu parsen. Bei Erfolg heißt es das Blob ist ein json, und kein pdf
        try {
            const responseJson = JSON.parse(await responseBlob.text());
            if(!responseJson.isReportReady){
                setInfoMsg("Dieser Bericht wird gerade noch vorbereitet. Versuche es bitte kurz später noch einmal")
                setInfoOpen(true);
            }
    
        } 
        // wenn das blob in json nicht umgewandelt werden kann dann ist das blob ein pdf.
        catch (error) {

            const pdfBlob = new Blob([report.data],{type:'application/pdf;charset=UTF-8'});
            const fileUrl = URL.createObjectURL(pdfBlob); 

            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.download = `${projectToView.customerId}_${projectToView.name}_${reportDate}.pdf`;
            downloadLink.click();       

        }

    }


    return (
        <div className="reports-list-item">
            <span>{index}</span>
            <span>{reportDate}</span>

            <Button 
                className="pdf-btn" 
                color="success"
                size="sm"
                onClick={showReport}
                title="Bericht im Browser ansehen"
            >
                <img width="35px" src={require ("../../assets/imgs/show-pdf.png")} alt="download-pdf" />
            </Button>

            <Button 
                className="pdf-btn" 
                color="success"
                size="sm"
                onClick={downloadReport}
                title="Bericht als PDF herunterladen"
            >
                <img width="35px" src={require ("../../assets/imgs/download-pdf.png")} alt="download-pdf" />
            </Button>
        </div>
    )
}


export {ReportsListItem};