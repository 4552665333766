import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.scss';
import {ProjectsOverview} from './routes/home/projects-overview.route.component.jsx';
import {TopBar} from './components/top-bar/top-bar.component.jsx';
import {ConfirmMsg} from "./components/confirm-msg/confirm-msg.component.jsx";
import {InfoMsg} from "./components/info-msg/info-msg.component.jsx";
import {SpinnerModal} from "./components/spinner-modal/spinner-modal.component.jsx";
import {ErrorMsg} from "./components/error-msg/error-msg.component.jsx";
import {CreateProject} from "./routes/creat-project/create-project.route.component.jsx";
import {SingleProjectOverview} from "./routes/single-project-overview/single-project-overview.route.component.jsx";
import {Authentication} from "./routes/user-authentication/user-authentication.route.component.jsx";
import { SignupNewUser } from "./components/signup-new-user/signup-new-user.context.jsx";


export function App() {

  return (
    <div className="App">

      {/* Routes */}
      <Routes>
        <Route path="/" element={<TopBar/>}>
          <Route index element={<ProjectsOverview />} />
          <Route path="create-project" element={<CreateProject/>} />
          <Route path="single-project-overview" element={<SingleProjectOverview/>} />
          <Route path="authentication" element={<Authentication/>} />
        </Route>
      </Routes>

      {/* Subwindows (Modals) */}
      <SignupNewUser></SignupNewUser>
      <ConfirmMsg></ConfirmMsg>
      <InfoMsg></InfoMsg>
      <SpinnerModal></SpinnerModal>
      <ErrorMsg></ErrorMsg>
    </div>
  );
}


