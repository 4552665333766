import axios from "axios";
import { useContext } from "react";
import { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { Confirmation } from "./confirmation.context.jsx";
import { GlobalVariablesContext } from "./global-variables.context.jsx";

export const AuthenticationContext = createContext({
    isLoggedIn:false,
    setIsLoggedIn: ()=>{},
    login: ()=>{},
    logout: ()=>{},
    reAuthUser: ()=> {},
    userData:{}, 
    setUserData: ()=> {}
})


export const AuthenticationProvider = ({children}) => {
    
    const navigate = useNavigate();
    const location = useLocation();

    const defaultUserData = {username:"",isAdmin:false}
    // States
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState(defaultUserData);
 
    // Contexts
    const {apiUrl,apiKey,setApiKey} = useContext(GlobalVariablesContext);
    const {setConfirmMsg,setConfirmOpen,setConfirmTitle,setOnConfirm} = useContext(Confirmation);

    //wann immer isLoggedIn sich ändert Benutzer erneut authentifizieren. (z.B beim neu laden der Seite)
    useEffect(() => {
        // zuletzt aufgerufene Route in localStorage speichern, 
        // um nach erfolgreicher Authentifizierung wieder dahin weiterleiten zu können.
        const lastCalledRoute = location.pathname;
        localStorage.setItem("lastCalledRoute",lastCalledRoute);

        reAuthUser();

    }, [isLoggedIn]);



    /**
     * Den Benutzer erneut authentifizieren. (Persistent User Login)
     * @returns boolean
     */
    const reAuthUser = async ()=>{
        
        //wenn user im cache vorhanden ist.
        if(localStorage.getItem("username")){
            const user = localStorage.getItem("username");
            const token = localStorage.getItem("userToken");

            const isAuthenticatedUserResponse = await axios({
                method:'post',
                url:`${apiUrl}/reAuthUser`,
                headers:{
                    'content-type': 'application/json',
                },
                data:{ 
                    function:"reAuthUser",
                    username:user,
                    token:token,
                    apiKey:apiKey
                }
            })
    
            const isAuthenticatedUser = await isAuthenticatedUserResponse.data.isAuthenticated;

            // wenn der Benutzer mit den in localStorage hintelegten Daten authentifiziert werden kann.
            if(isAuthenticatedUser){
    
                const isAdmin = isAuthenticatedUserResponse.data.isAdmin;
                setIsLoggedIn(true);
                setUserData({username:user,isAdmin:isAdmin});
            }
            // wenn der Benutzer mit den in localStorage hintelegten Daten nicht authentifiziert werden kann.
            else{

                //falsche daten aus localStorage entfernen.
                localStorage.removeItem("username");
                localStorage.removeItem("userToken");
                localStorage.removeItem("apiKey");
                
                setIsLoggedIn(false)
            }
        //wenn kein user im cache vorhanden ist.     
        }else{
            setIsLoggedIn(false);
        }

    }



    /**
     * Wenn username und password stimmen wird eingeloggt. sonst gibt false zurück.
     * @param {String} user 
     * @param {String} pass 
     * @returns false wenn username oder password nicht stimmt.
     */
    const login = async (user,pass) => {

        try {
            const isAuthenticatedUserResponse = await axios({
                method:'post',
                url:`${apiUrl}/login`,
                headers:{
                    'content-type': 'application/json',
                },
                data:{ 
                    function:"login",
                    username:user,
                    password:pass        
                }
            })
    
            const isAuthenticatedUser = await isAuthenticatedUserResponse.data.isAuthenticated;

            if(isAuthenticatedUser){
                //vom benutzer angegebenen username und von backend erhaltenen Token für spätere Authentifitzierungen (page-refresh) in localStorage speichern.
                localStorage.setItem("username",user);
                localStorage.setItem("userToken",isAuthenticatedUserResponse.data.token);
                localStorage.setItem("apiKey",isAuthenticatedUserResponse.data.apiKey);
                
                //das erhaltene apiKey global setzen.
                setApiKey(isAuthenticatedUserResponse.data.apiKey);
                

                const isAdmin = isAuthenticatedUserResponse.data.isAdmin;
                setIsLoggedIn(true);
                setUserData({username:user,isAdmin:isAdmin});
                navigate("/",{replace:true});

                return true;
            }
            else{
                return false;
            }
    
        } catch (error) {
            if(error.code === "ERR_NETWORK"){
                setConfirmTitle("Verbindungsfehler");
                setConfirmMsg("Eine Verbindung mit der API (Backend) ist nicht möglich! nochmal versuchen?");
                setConfirmOpen(true);
                setOnConfirm(()=>()=>{login(user,pass)})
            }
        }
    }


    const logout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem("username");
        localStorage.removeItem("userToken");
        localStorage.removeItem("apiKey");
        navigate("/authentication",{replace:true});
    }



    const value = {isLoggedIn, setIsLoggedIn, login, logout, reAuthUser,userData, setUserData};

    return (
        <AuthenticationContext.Provider value={value}>
            {children}
        </AuthenticationContext.Provider>
    )
}