import { createContext, useEffect, useState } from "react";



export const Confirmation = createContext({
    confirmOpen:false,
    setConfirmOpen: ()=>{},
    confirmMsg:"",
    setConfirmMsg: ()=>{},
    confirmTitle:"",
    setConfirmTitle: ()=>{},
    setOnConfirm:"",
    onConfirm: "",
    type:"",
    setType:()=>{}
})



export const ConfirmationProvider = ({children}) => {
   
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState(false);
    const [onConfirm,setOnConfirm] = useState();


    // const onConfirm = (cb) => {
    //     cb();
    // }
   
    // useEffect(()=>{
    //     setType("")
    // },)


    const value = {confirmOpen, setConfirmOpen, confirmMsg, setConfirmMsg, confirmTitle, setConfirmTitle, onConfirm,setOnConfirm};

    return (
        <Confirmation.Provider value={value}>
            {children}
        </Confirmation.Provider>
    )
}