import './single-project-overview.route.styles.scss';

import { useContext } from 'react';
import axios from "axios";
import {ProjectForm} from '../../components/project-form/project-form.component.jsx';
import { SingleProjectOverviewContext } from '../../contexts/single-project-overview.context.jsx';
import { SpinnerContext } from '../../contexts/spinner.context.jsx';
import {NativeButton} from '../../components/native-button/native-button.component.jsx';
import { useNavigate } from 'react-router-dom';
import { Confirmation } from '../../contexts/confirmation.context.jsx';
import {ReportsList} from '../../components/reports-list/reports-list.component.jsx';
import { ReportsListContext } from '../../contexts/reports-list.context.jsx';
import { ProjectsStatusContext } from '../../contexts/projects-status.context.jsx';
import { GlobalVariablesContext } from '../../contexts/global-variables.context.jsx';


const SingleProjectOverview = () => {    

    const { 
        projectToView,
        setProjectToView,
        projectToViewKeywords,
        setProjectToViewKeywords,
        projectToViewWebsites,
        setProjectToViewWebsites,
        isUneditable,
        setIsUneditable} = useContext(SingleProjectOverviewContext);

    const { setSpinnerOpen } = useContext(SpinnerContext);
    const { setOpenReportsList } = useContext(ReportsListContext);
    const { setConfirmMsg,setConfirmTitle,setConfirmOpen,setOnConfirm } = useContext(Confirmation);
    const { setAreProjectsUpdated } = useContext(ProjectsStatusContext);
    const {apiUrl,apiKey} = useContext(GlobalVariablesContext);


    const navigate = useNavigate();


    //wenn es kein Projekt zum Anzeigen gibt zur Home weiterleiten.
    // if(!projectToView.name){
    //     navigate("/",{replace:true});
    // }



    const onClose = () => {

        if(!isUneditable){
            
            setConfirmTitle("Nicht gespeicherte Daten");
            setConfirmMsg("Alle Eingegebenen daten werden verworfen. Trotzdem schlißen?")
            setConfirmOpen(true);

            //hier 2 verschachtelete funktionen rein passen. die 2. wird später 
            // durch die 1. in confirm-msg-component aufgerufen.
            setOnConfirm(()=> async()=>{
                setIsUneditable(true);
                navigate("/")
            });

            //funktion hier abbrechen
            return;
        }

        navigate("/");

    }



    const updateProject = async (projectToUpdate) => {

        setSpinnerOpen(true);

        const updateProjectResponse = await axios({
            method:"post",
            url:`${apiUrl}/updateProject`,
            headers:{
                'content-type': 'application/json',
            },
            data:{ 
                function:"updateProject",
                projectToUpdate,
                apiKey:apiKey   
            }
    
        });

        const {id,name,customerId,billId,type,frequency,depth,keywords,websites} = projectToUpdate;
        
        if(updateProjectResponse.data){

            setIsUneditable(true);

            //neu Daten übernehmen um die Aktuellen Daten nach der Änderungen anzuzeigen.
            setProjectToView({id,name,customerId,billId,type,frequency,depth});

            setProjectToViewKeywords(keywords);
            setProjectToViewWebsites(websites);
            setSpinnerOpen(false);

            //um beim zurückkehren zur Projekten-Übersicht, Projekte neu abzufragen, und nicht die gecacheten anzeigen.
            setAreProjectsUpdated(true);
        }

    }



    return (
        <div className="single-project-overview-container">
            <div className="single-project-overview-box-header">

                <h1 className='single-project-overview-box-header-title'> {projectToView.name} </h1>

                <NativeButton 
                    className="single-project-overview-box-close-btn" 
                    buttonType="close" 
                    onClick={onClose}
                    title="Projekt Schließen"
                >X</NativeButton>

            </div>

            <ProjectForm 
                isProjectOverview={true} 
                updateProject = {updateProject}
            />

            <ReportsList  />
        </div>
    )
}

export {SingleProjectOverview};