import { createContext, useEffect, useState } from "react";


export const ProjectsStatusContext = createContext({
    areProjectsUpdated:true,
    setAreProjectsUpdated: ()=>{},
})



export const ProjectsStatusProvider = ({children}) => {
    
    // Initialwert auf true setzen. beim ersten mal werden projekte vom Backend abgefragt,
    // und dann dies auf false gesetzt.
    const [areProjectsUpdated, setAreProjectsUpdated] = useState(true);


    const value = {areProjectsUpdated, setAreProjectsUpdated};

    return (
        <ProjectsStatusContext.Provider value={value}>
            {children}
        </ProjectsStatusContext.Provider>
    )
}