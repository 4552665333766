// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".reports-list-item{display:grid;grid-template-columns:10% 80% 5% 5%;padding:4px 0;align-items:center;border-bottom:dotted 1px gray}.reports-list-item:hover{background-color:#d3d3d3}.reports-list-item .pdf-btn{padding:0;justify-self:center;background-color:rgba(0,0,0,0);border:none}", "",{"version":3,"sources":["webpack://./src/components/reports-list-item/reports-list-item.styles.scss"],"names":[],"mappings":"AAEA,mBACI,YAAA,CACA,mCAAA,CACA,aAAA,CACA,kBAAA,CACA,6BAAA,CAEA,yBACI,wBAAA,CAGJ,4BACI,SAAA,CACA,mBAAA,CACA,8BAAA,CACA,WAAA","sourcesContent":["@import 'bootstrap/dist/css/bootstrap.min.css';\n\n.reports-list-item{\n    display: grid;\n    grid-template-columns: 10% 80% 5% 5%;\n    padding: 4px 0;\n    align-items: center;\n    border-bottom: dotted 1px gray;\n\n    &:hover{\n        background-color: lightgray;\n    }\n    \n    .pdf-btn{\n        padding: 0;\n        justify-self: center;\n        background-color: transparent;\n        border:none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
