import { createContext, useContext, useEffect, useState } from "react";
//import { AuthenticationContext } from "./authentication.context";



export const GlobalVariablesContext = createContext({
    apiUrl:"",
    apiKey:"",
    setApiKey:""
})


export const GlobalVariablesContextProvider = ({children}) => {
    
    
    const apiUrls = {
        // development:"http://localhost:8000",
        development:"http://localhost/awr.php",
        productionHttp:"http://85.25.214.24:8000",
        productionHttps:"https://malta1020.startdedicated.de:8443",
    }

    //Zwischen Dev und Prod toggeln.
    const apiUrl = apiUrls.productionHttps;
    const [apiKey,setApiKey] = useState(localStorage.getItem('apiKey'));
    
    

    const value = { apiUrl,apiKey,setApiKey };

    return (
        <GlobalVariablesContext.Provider value={value}>
            {children}
        </GlobalVariablesContext.Provider>
    )
}