// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checked-projects-list-item{display:grid;grid-template-columns:10% 80% 10%;margin-bottom:8px;align-items:center}.checked-projects-list-item .delete-projekt-from-checked-list{height:25px;width:25px;line-height:25px;font-size:20px;padding:0;justify-self:center}", "",{"version":3,"sources":["webpack://./src/components/checked-projects-list-item/checked-projects-list-item.styles.scss"],"names":[],"mappings":"AAEA,4BACI,YAAA,CACA,iCAAA,CACA,iBAAA,CACA,kBAAA,CAEA,8DACI,WAAA,CACA,UAAA,CACA,gBAAA,CACA,cAAA,CACA,SAAA,CACA,mBAAA","sourcesContent":["@import 'bootstrap/dist/css/bootstrap.min.css';\n\n.checked-projects-list-item{\n    display: grid;\n    grid-template-columns: 10% 80% 10%;\n    margin-bottom: 8px;\n    align-items: center;\n\n    .delete-projekt-from-checked-list{\n        height: 25px;\n        width: 25px;\n        line-height: 25px;\n        font-size: 20px;\n        padding: 0;\n        justify-self: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
