
import { useContext, useEffect, useState } from "react";
import "./page-index-item.styles.scss";

const PageIndexItem = ({startChunk,chunkedProjectsArray, indexClickHandler, chunkToRender}) => {
    

    return (
        chunkedProjectsArray.map((_,index)=>{
            return(
                index>=startChunk&& index<(startChunk+10)&&
                <span key={index} className={`page-index-item ${index===chunkToRender?"selected":""}`} onClick={(event)=>indexClickHandler(index,event)}>
                    {index+1}
                </span>
            )
        })
    )
}

export {PageIndexItem};