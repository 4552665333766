import { createContext, useEffect, useState } from "react";


// dieses context bietet ein Array von gecheckten projekten, 
// um diese auf einmal löschen, bearbeiten usw.. zu können.
export const CheckedProjectsContext = createContext({
    checkedProjects:[],
    setCheckedProjects: ()=>{},
    openCheckedProjectsList:false,
    setOpenCheckedProjectsList:()=>{},
    resetCheckedProjects: ()=>{},
    deleteFromCheckedProjects: ()=>{},
})



export const CheckedProjectsProvider = ({children}) => {
   
    const [checkedProjects, setCheckedProjects] = useState([]);
    const [openCheckedProjectsList,setOpenCheckedProjectsList] = useState(false);
   

    const deleteFromCheckedProjects = (projectIdToDelete) => {
        const newList = checkedProjects.filter((checkedProject) => {
            return checkedProject.id !== projectIdToDelete;
        })
        setCheckedProjects(newList);
    }

    const resetCheckedProjects = () => {
        setCheckedProjects([]);
    }

    const value = {
        checkedProjects, 
        setCheckedProjects, 
        resetCheckedProjects, 
        openCheckedProjectsList,
        setOpenCheckedProjectsList,
        deleteFromCheckedProjects
    };

    return (
        <CheckedProjectsContext.Provider value={value}>
            {children}
        </CheckedProjectsContext.Provider>
    )
}