// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".info-msg-modal-body{max-height:50vh;overflow-y:scroll}", "",{"version":3,"sources":["webpack://./src/components/info-msg/info-msg.styles.scss"],"names":[],"mappings":"AAEA,qBACI,eAAA,CACA,iBAAA","sourcesContent":["@import 'bootstrap/dist/css/bootstrap.min.css';\n\n.info-msg-modal-body{\n    max-height: 50vh;\n    overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
