import './signup-new-user.styles.scss';

import axios from 'axios';
import { useContext, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ErrorMsgContext } from '../../contexts/error-msg.context.jsx';
import { GlobalVariablesContext } from '../../contexts/global-variables.context.jsx';
import { Information } from '../../contexts/info-msg.context.jsx';
import { SignupNewUserContext } from '../../contexts/signup-new-user.context.jsx';
import { SpinnerContext } from '../../contexts/spinner.context.jsx';
import {FormInput} from '../form-input/form-input.component.jsx';

export const SignupNewUser = () => {
    const defaultInputValues = {
        username:"",
        password:"",
        passwordRepeat:"",
        isAdmin:false
    };
    //----------
    // States
    //----------
    const [inputValues, setInputValues] = useState(defaultInputValues);
    const [showMsg,setShowMsg] = useState(false);
    const [msg,setMsg] = useState("");

    //----------
    // Contexts
    //----------
    const {openSignupNewUser,setOpenSignupNewUser} = useContext(SignupNewUserContext);
    const {setSpinnerOpen} = useContext(SpinnerContext);
    const {setInfoMsg,setInfoOpen} = useContext(Information);
    const {setErrorMsg,setErrorOpen} = useContext(ErrorMsgContext);
    const {apiUrl,apiKey} = useContext(GlobalVariablesContext);



    // inputs change handler
    const onChangeInput = (event)=>{
        const {name,value,checked} = event.target;

        //nachricht wieder verbergen.
        setShowMsg(false);

        if(name === "isAdmin"){
            setInputValues({...inputValues,isAdmin:checked});
        }
        else{
            //leerzeichen entfernen.
            let valueWithoutSpaces = value.replace(" ","");

            //benutzername in kleinbuchstaben umwandeln.
            if(name === "username"){
                valueWithoutSpaces = valueWithoutSpaces.toLowerCase();
            }
            setInputValues({...inputValues,[name]:valueWithoutSpaces});
        }
    }


    /**
     * Benutzer anlegen
     */
    const signupUser = async ()=>{

        //validate check
        if(inputValues.username.trim().length < 1 || inputValues.password.trim().length < 1){
            setMsg("Der Benutzername, und das Passwort dürfen nicht leer sein!");
            setShowMsg(true);
            return;
        }
        if(inputValues.password.trim().length < 6){
            setMsg("Passwort soll min. 6 Zeichen lang sein!");
            setShowMsg(true);
            return;
        }
        if(inputValues.password !== inputValues.passwordRepeat){
            setMsg("Passwörter stimmen nicht überein!");
            setShowMsg(true);
            return;
        }

        //spinner anzeigen.
        setSpinnerOpen(true);


        //den benutzer registrieren.
        try {
            const signUpResponse = await axios({
                method:"post",
                url:`${apiUrl}/signup`,
                headers:{
                    'content-type': 'application/json',
                },
                data:{ 
                    function:"signup",
                    signUpUserData:{...inputValues},
                    apiKey:apiKey          
                }
        
            });
    
            const signUpResponseObject = signUpResponse.data;
    
            if(signUpResponseObject.err){
                setSpinnerOpen(false);
    
                setMsg(signUpResponseObject.msg);
                setShowMsg(true);
                return;
            }
            else{
                setSpinnerOpen(false);
                setInfoMsg("Der Benutzer wurde erfolgreich angelegt!");
                setInfoOpen(true);
                setInputValues(defaultInputValues);
            }
    
        } catch (error) {
            setErrorMsg("Verbindungsfehler! Der Benutzer konnte nicht registriert werden.")
            setErrorOpen(true);
        }

    }


    return (
        
        <Modal
            fullscreen={false}
            size="lg"
            toggle={()=>{setOpenSignupNewUser(false)}}
            isOpen={openSignupNewUser}
            centered={true}
        >
            <ModalHeader toggle={()=>{setOpenSignupNewUser(false)}} className="reports-list-modal-header" >
                Neuen Benutzer anlegen
            </ModalHeader>

            <ModalBody>

                <form className='signup-new-user-form'>

                    <FormInput 
                        isValid={true} 
                        label="Benutzername"
                        name="username"
                        value={inputValues.username}
                        onChange={onChangeInput}
                    />

                    <FormInput 
                        isValid={true} 
                        label="Passwort" 
                        name="password" 
                        value={inputValues.password}
                        onChange={onChangeInput}                        
                        type="password"
                    />

                    {inputValues.password.trim().length < 6&&
                        <span className='signup-new-user-password-notice'>Min. 6 Zeichen</span>
                    }

                    <FormInput 
                        isValid={true} 
                        label="Passwort wiederholen" 
                        name="passwordRepeat" 
                        value={inputValues.passwordRepeat}
                        onChange={onChangeInput}
                        type="password"
                    />

                    <div className="signup-is-admin-wrapper">
                        <label htmlFor="isAdmin">Admin ?</label>
                        <input 
                            className='signup-is-admin-checkbox' 
                            type="checkbox" 
                            name="isAdmin" 
                            value={false}
                            onChange={onChangeInput}
                        />
                    </div>

                    {showMsg&&
                        <span className='signup-new-user-form-msg-box'>{msg}</span>
                    }
                </form>

            </ModalBody>

            <ModalFooter>

                <Button color="danger" onClick={() => {setOpenSignupNewUser(false)}}>
                    Abbrechen
                </Button>

                <Button color="success" onClick={signupUser}>
                    Registrieren
                </Button>

            </ModalFooter>
        </Modal>
    )
}
