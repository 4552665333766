
import "./native-button.styles.scss";

export const NativeButton = ({buttonType,className,...otherProps})=> {

    const BUTTON_TYPE_CLASSES = {
        enter: "enter",
        paginator: "paginator",
        delete: "delete",
        clear: "clear",
        close: "close",
    }

    return(
        <button className={`button-container ${className} ${BUTTON_TYPE_CLASSES[buttonType]}`}
        {...otherProps}
        >
        </button>
    )
}
